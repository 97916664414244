import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartDoughnut = ({ data, colores, vistaAvanzada }) => {
  const [dataChart, setDatachart] = useState({
    labels: data.map((d) => d.estado),
    datasets: [
      {
        data: data.map((d) => d.casos.toLocaleString("es-AR")),
        porcent: data.map((d) => d.porcentaje.toLocaleString("es-AR")),
        backgroundColor: colores,
        borderWidth: 1,
        borderColor: "transparent",
      },
    ],
  });

  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: vistaAvanzada ? "Todos los contactos" : "Contactos efectivos",
        padding: {
          bottom: 16,
        },
        font: {
          size: 16,
          weight: "bold",
        },
      },

      datalabels: {
        display: "auto",
        color: "#222",
        backgroundColor: "rgba(255,255,255,.5)",
        padding: { left: -50 },
        borderRadius: 3,
        formatter: function (value, context) {
          return (
            "                " +
            context.chart.data.datasets[0].porcent[context.dataIndex] +
            "%"
          );
        },
      },
    },
  };

  useEffect(() => {
    setDatachart({
      labels: data.map((d) => d.estado),
      datasets: [
        {
          data: data.map((d) => d.casos.toLocaleString("es-AR")),
          porcent: data.map((d) => d.porcentaje.toLocaleString("es-AR")),
          backgroundColor: colores,
          borderWidth: 1,
          borderColor: "transparent",
        },
      ],
    });
  }, [data]);

  return (
    <>
      <Doughnut
        data={dataChart}
        options={options}
        key={JSON.stringify(data)}
        width={200}
        height={200}
      />
    </>
  );
};
