import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels
);

export const ChartBar = ({ data, colores, vistaAvanzada }) => {
  const getColor = (estado) => {
    let estadoSwitch = estado.toUpperCase();
    switch (estadoSwitch) {
      case "NÚMERO INVÁLIDO":
        return "#FFCDD2";
      case "CONTESTADOR":
        return "#FEEDAF";
      case "NO SE PUEDE CONTACTAR":
        return "#ECCFFF";
      case "PENDIENTE LLAMAR":
        return "#B3E5FC";
      default:
        return "#C8E6C9";
    }
  };

  const [dataChart, setDatachart] = useState({
    labels: data.map((d) => d.estado),
    datasets: [
      {
        data: data.map((d) => d.casos.toLocaleString("es-AR")),
        porcent: data.map((d) => d.porcentaje.toLocaleString("es-AR")),
        backgroundColor: colores,
        borderWidth: 1,
        borderColor: "transparent",
      },
    ],
  });

  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: vistaAvanzada ? "Todos los contactos" : "Contactos efectivos",
        padding: {
          bottom: 16,
        },
        font: {
          size: 16,
          weight: "bold",
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: "auto",
        color: "#222",
        backgroundColor: "rgba(255,255,255,.5)",
        padding: { left: -50 },
        borderRadius: 3,
        formatter: function (value, context) {
          return (
            "                " +
            context.chart.data.datasets[0].porcent[context.dataIndex] +
            "%"
          );
        },
      },
    },
  };

  useEffect(() => {
    setDatachart({
      labels: data.map((d) => d.estado),
      datasets: [
        {
          data: data.map((d) => d.casos.toLocaleString("es-AR")),
          porcent: data.map((d) => d.porcentaje.toLocaleString("es-AR")),
          backgroundColor: colores,
          borderWidth: 1,
          borderColor: "transparent",
        },
      ],
    });
  }, [data]);

  return (
    <>
      <Bar
        data={dataChart}
        options={options}
        key={JSON.stringify(data)}
        width={550}
        height={190}
      />
    </>
  );
};
