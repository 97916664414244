import http from "../common/components/http-common";

export const getDataChart = (id, vsitaAvanzada) => {
  let config = {
    headers: {},
    params: {
      esWebCliente: vsitaAvanzada ? false : true,
    },
  };
  return http.get<any>("/encuestas/dashboardGraphs/" + id, config);
};
